.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.HeaderLeftElement {
  font-family: monospace;
  font-size: 36px;
  flex-grow: 2;
  text-align: start;
}

.HeaderRightElement {
  font-size: 18px;
  flex-grow: .4;
}

.InvertedColors {
  background-color: black;
  color: white;
  padding: 5px 10px 5px 10px;
}

.HeadlineText {
  font-size: 28px;
}

.Body {
  width: 750px;
  display: flex;
  flex-direction: column;
}

.Footer {
  width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-size: 14px;
  margin-top: 20px;
}
